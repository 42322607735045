<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse","line-height": "normal",}'>

            <tr>
                <td
                :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                >
                    Signo
                </td>
                <td
                    v-for="hora in $store.state.enfermeria.horarios[turno]"
                    :key="hora" width="8%"
                    :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'

                >
                    <div :style="{'text-align': 'center'}">{{hora.split(':')[0]}}</div>
                </td>
            </tr>

            <tr
                v-for="signo in listSignosGral"
                :key="signo"
                :style="estilos.tablaBorde"
            >
                <td :style='{"padding":"0px 0px 0px 5px", "text-transform": "uppercase"}'>
                    {{signo}}
                </td>
                <td
                    v-for="hora in $store.state.enfermeria.horarios[turno]"
                    :key="hora"
                    width="8%"
                    :style="estilos.tablaBorde"
                >
                    <div v-if="signo != 'ta'" :style="{'text-align': 'center', 'font-size':'6pt'}">{{datTabla(signo, hora)}}</div>
                    <div v-if="signo == 'ta'" :style="{'text-align': 'center', 'font-size':'6pt'}">{{datTabla(signo, hora).split('\/').join ('\/ ')}}</div>
                </td>
            </tr>
            <!-- DEL VENTILADOR -->
            <tr v-if="(listSignosVent.lengtg > 0)">
                <td
                    :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3", "text-align":"center"}}'
                    :colspan="$store.state.enfermeria.horarios[turno].length + 1"
                >
                    Parámetros de Oxigenoterapia
                </td>
            </tr>
            <tr v-if="(listSignosVent.lengtg > 0)">
                <td
                :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                >
                    Parámetro
                </td>
                <td
                    v-for="hora in $store.state.enfermeria.horarios[turno]"
                    :key="hora" width="8%"
                    :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'

                >
                    <div :style="{'text-align': 'center'}">{{hora.split(':')[0]}}</div>
                </td>
            </tr>
            <tr
                v-for="signo in listSignosVent"
                :key="signo"
                :style="estilos.tablaBorde"
            >
                <td :style='{"padding":"0px 0px 0px 5px", "text-transform": "uppercase"}'>
                    {{signo}}
                </td>
                <td
                    v-for="hora in $store.state.enfermeria.horarios[turno]"
                    :key="hora"
                    width="8%"
                    :style="estilos.tablaBorde"
                >
                    <div v-if="signo != 'ta'" :style="{'text-align': 'center', 'font-size':'6pt'}">{{datTabla(signo, hora)}}</div>
                    <div v-if="signo == 'ta'" :style="{'text-align': 'center', 'font-size':'6pt'}">{{datTabla(signo, hora).split('\/').join ('\/ ')}}</div>
                </td>
            </tr>

        </table>

    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],
    data:()=>({
        signosTv:['tipov', 'tinsp', 'pinsp', 'peep', 'fio2', 'vcor', 'frecv']

    }),

    methods:{

        datTabla(signo, hora){
            //console.log(signo)
            //console.log(hora)
            let estaHora = hora.split(':')[0]
            //console.log(estaHora)
            //console.log(this.datos.signosHoras)

            let val = this.datos.signosHoras[estaHora].filter(dt=>dt.signo == signo)

            if (val.length>0){
                return (val.map(itm=>itm.valor).join(', '))
            } else {
                return ''
            }
        },

    },

    computed:{
        listSignosGral(){
            return (this.datos.listaSignos.filter(x => !this.signosTv.includes(x)))
        },
        listSignosVent(){
            return (this.datos.listaSignos.filter(x => this.signosTv.includes(x)))
        },
    },
    watch:{
    },

}
</script>

<style>

</style>